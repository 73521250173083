import React from 'react';
import styled from 'styled-components';
import { Modal as AntModal, ModalProps } from 'antd';

type PropsType = {
   footer?: JSX.Element
   center?: boolean
   closable?: boolean
   width?: number | string
   bodyPaddingX?: string
   bodyPaddingY?: string
   noBodyPadding?: boolean
   overflowHidden?: boolean
   simple?: boolean
   scroll?: boolean
   asContainer?: boolean
   thin?: boolean
}

const Modal = styled(AntModal)<PropsType>`
   .ant-modal-centered & {
      padding-top: 24px;
   }
   .ant-modal-content {
      border-radius: 5px;
      padding: 0;
      ${props => props.asContainer && `
         background-color: transparent;
         box-shadow: none;
      `}
      ${props => props.overflowHidden && `overflow: hidden;`}
      .ant-modal-header {
         padding: ${props => props.thin ? '15px 20px' : '20px 25px'};
         margin-bottom: ${props => props.thin ? '0px' : '8px'};
         background-color: transparent;
         border-bottom: 1px solid var(--light-grey);
         
         .ant-modal-title {
            font-weight: normal;
         }
      }
      .ant-modal-body {
         padding: ${props => props.noBodyPadding || props.simple ? 0 : props.bodyPaddingY || props.thin ? '15px' : `20px`} ${props => props.noBodyPadding || props.simple ? 0 : props.bodyPaddingX || props.thin ? '20px' : `25px`};
      }
      
      div {
         box-sizing: border-box;
      }
   }
`;

Modal.defaultProps = {
   footer: null,
   center: true,
   closable: false,
   width: 350,
   noBodyPadding: false,
   overflowHidden: false,
   simple: false,
   scroll: false,
   asContainer: false
};

export default Modal;

export const ModalInner = styled.div<{css?: string}>`
   padding: 20px 25px;
   ${props => props.css}
`;

export const ModalTitle = styled.div<{css?: string}>`
   font-family: var(--title-font);
   text-transform: capitalize;
   letter-spacing: 0.5px;
   font-size: 16px;
   font-weight: normal;
   ${props => props.css}
`;

export const afterModalClose = (callback) => {
   setTimeout(() => {
      callback();
   }, 500);
}
export const ModalScrollInner = styled(ModalInner)`
   overflow: auto;
   ${props => props.css}
`;

export const FullModal = (props: ModalProps): React.ReactElement => {

   return (
      <AntModal
         centered
         style={{ top: 0, left: 0, right: 0, bottom: 0, margin: 0, padding: 0, maxWidth: 'none', ...props.style }}
         width='100%'
         height='100%'
         footer={null}
         styles={{
            content: {
               minHeight: '100vh',
               backgroundColor: 'var(--color-body-background)'
            }
         }}
         {...props}
      ></AntModal>
   );
}

export const FullModalInner = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;
export const FullModalStickyHeader = styled.div`
   position: sticky;
   top: 0;
   z-index: 10000;
   background: #fff;
   border-bottom: 2px solid var(--color-border);
   margin: -20px -24px 20px;
   padding: 15px 24px;
`;
