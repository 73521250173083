import React from "react";
import styled from "styled-components";

export const HtmlContent = styled.div`
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  blockquote {
    border-left: 3px solid #bbb;
    padding-left: 15px;
    margin: 10px 0;
    color: #444;
  }
`;
